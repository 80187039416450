<template>
  <v-container>
    <h3>ประวัติการใช้งานโปรโมชัน</h3>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headerspromotion"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon @click="viewProduct(item)">mdi-eye</v-icon>
                <v-icon @click="UpdateProduct(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteProduct(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      headerspromotion: [
        { text: "ชื่อลูกค้า", value: "count1", align: "center" },
        { text: "รหัสโปรโมชัน", value: "count2", align: "center" },
        { text: "ชื่อโปรโมชัน", value: "count3", align: "center" },
        { text: "ออเดอร์ที่", value: "count4", align: "center" },
        { text: "วันที่ใช้", value: "count5", align: "center" },
      ],
      items: [
        {
          count1: "สามหมาย สายเสมอ",
          count2: "FABUARY2021",
          count3: "โปรเดือนกุมภา",
          count4: "SKU668811",
          count5: "12/02/2021",
        },
        {
          count1: "สมศรี มีโชค",
          count2: "GETAWAY001",
          count3: "แจกฟรีนาทีทอง",
          count4: "SKU442011",
          count5: "12/02/2021",
        },
        {
          count1: "สมปอง ทองสุข",
          count2: "FABUARY2021",
          count3: "โปรเดือนกุมภา",
          count4: "SKU900011",
          count5: "12/02/2021",
        },
        {
          count1: "เทพทอง ดีงาม",
          count2: "FABUARY2021",
          count3: "โปรเดือนกุมภา",
          count4: "SKU123011",
          count5: "12/02/2021",
        },
      ],
    };
  },
};
</script>